import React, { useState, useRef, useEffect } from 'react';
import subcategory from '../../Images/fileupload.jpg'
import './SubCategoryEdit.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
// import defaultCatImage from '../../Images/logo.png';
import Cropper from 'react-cropper';
import addImg from '../../Images/add.png'
import removeImg from '../../Images/remove.png'
import { API_URL } from '../../Const'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import gif from '../../Images/Intersection.gif'


const SubCategoryEdit = () => {
    const { state } = useLocation();



    const navigate = useNavigate();
    const [catImage, setCatImage] = useState(subcategory);
    const [isEditing, setIsEditing] = useState(false);
    const [iscropping, setIsCropping] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(null);
    const cropperRefsubCat = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [enableRemoveImg, enableRemove] = useState(false);
    const [details, setData] = useState();
    const [pageType, setType] = useState();
    const [isLoading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [catList, setCatList] = useState([]);
    const [category_id, setCategoryID] = useState("");
    const user = 1;

    useEffect(() => {
        if (state != null) {
            const { type } = state
            if (state.data !== undefined && state.data != null) {
                const { data } = state
                setData(data);

                if (type === "category") {
                    setCatImage(data['category_image_url'])
                    setName(data['category_name'])
                    setDescription(data['category_description'])
                }
                else if (type === "subcategory") {
                    setCatImage(data['sub_category_image_url'])
                    setName(data['sub_category_name'])
                    setDescription(data['sub_category_description'])
                }

            }
            else {
                getCategoryList();
                setIsEditing(true);
                setCatImage(null)
            }
            setType(type);

            
        }
        else {
            getCategoryList();
            setIsEditing(true);
            setCatImage(null)
        }
        setLoading(false);
    }, [])

    const handleChange = (value, ty) => {
        if (ty === 'name') {
            setName(value)
        }
        else if (ty === 'desc') {
            setDescription(value)
        }
    }

    const toggleRemove = () => {
        enableRemove(!enableRemoveImg);
    }

    const getCategoryList = async() => {
        await axios.get(API_URL + "/getAllActiveCategory").then(function (response) {
            console.log(response.data.result_Arr);
            setCatList(response.data.result_Arr)
        })
    }

    const [imageList, updateImageList] = useState([]);


    const validateInputs = () => {
        if (!name) {
            const msg = pageType === "category" ? 'Category Name is required.' : 'SubCategory Name is required.'
            alert(msg);
            return false;
        }

        if (!description) {
            const msg = pageType === "category" ? 'Category Description is required.' : 'SubCategory Description is required.'
            alert(msg);
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        if (validateInputs()) {
            try {
                if (pageType === "category") {
                    if (details !== undefined && details !== null) {
                        const datai = {
                            category_id: details['category_id'],
                            category_description: description,
                            category_image_url: catImage === subcategory?catImage:null,
                            category_name: name,
                            category_updated_by: user
                        };
                        await axios.post(API_URL + "/updateCategoryById", datai).then(function (response) {

                            if (response.data.status === "success") {
                                alert(response.data.message);
                                setIsCropping(false);
                                setIsEditing(false);
                                navigate(-1);
                            }
                            else if (response.data.status === "failure") {
                                alert(response.data.message);
                            }
                        })
                    }
                    else {
                        const datai = {
                            category_description: description,
                            category_image_url: catImage === subcategory?catImage:null,
                            category_name: name,
                            category_updated_by: user,
                            category_is_active:1,
                            category_created_by:user,
                        };

                        await axios.post(API_URL + "/addNewCategory", datai).then(function (response) {

                            if (response.data.status === "success") {
                                alert(response.data.message);
                                setIsCropping(false);
                                navigate(-1);
                            }
                            else if (response.data.status === "failure") {
                                alert(response.data.message);
                            }
                        })
                    }

                }
                else if (pageType === "subcategory") {
                    
                    if (details !== undefined && details !== null) {
                        
                        const datai = {
                            sub_category_id: details['sub_category_id'],
                            sub_category_description: description,
                            sub_category_image_url: catImage === subcategory?catImage:null,
                            sub_category_name: name,
                            sub_category_updated_by: user,
                        };
                        await axios.post(API_URL + "/updateSubCategoryById", datai).then(function (response) {
                            if (response.data.status === "success") {
                                alert(response.data.message);
                                setIsCropping(false);
                                setIsEditing(false);
                                navigate(-1);
                            }
                            else if (response.data.status === "failure") {
                                alert(response.data.message);
                            }
                        })
                    }
                    else{
                        const datai = {
                            category_id: category_id,
                            sub_category_description: description,
                            sub_category_image_url: catImage === subcategory?catImage:null,
                            sub_category_name: name,
                            sub_category_updated_by: user,
                            sub_category_created_by : user
                        };
                        alert("subCat")
                        await axios.post(API_URL + "/addNewSubCategory", datai).then(function (response) {
                            
                            if (response.data.status === "success") {
                                alert(response.data.message);
                                setIsCropping(false);
                                navigate(-1);
                            }
                            else if (response.data.status === "failure") {
                                alert(response.data.message);
                            }
                        })
                    }
                }

            } catch (error) {
                console.log(error);
            }



        }
    };

    const onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file.size > 1 * 1024 * 1024) {
                alert('File size exceeds 1MB');
                return;
            }
            const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (!validImageTypes.includes(file.type)) {
                alert('Invalid file type. Please select an image.');
                return;
            }
            const reader = new FileReader();
            reader.onload = () => {
                setImageToCrop(reader.result);
                setIsCropping(true);
            };
            reader.readAsDataURL(file);
        }
    };
    const onCrop = () => {
        const cropper = cropperRefsubCat.current?.cropper;
        if (cropper) {
            const croppedDataUrl = cropper.getCroppedCanvas().toDataURL();

            setCatImage(croppedDataUrl);
            const lis = imageList;
            if (lis.length > currentIndex) {

                lis[currentIndex].image = croppedDataUrl;

            }
            else {
                lis.push({ "index": lis.length, "image": croppedDataUrl })

            }
            updateImageList(lis);
            setImageToCrop(null);
            setIsCropping(false);
        }
    };

    const cancelCrop = () => {
        setImageToCrop(null);
        setIsCropping(false);
    }

    const handleImageCrop = () => {

        if (imageList !== undefined && imageList.length > 0) {
            setImageToCrop(catImage); setIsCropping(true)
        }
        else {
            document.getElementById('fileInput').click()
        }
    }

    const removeImage = (index) => {
        var lis = imageList;
        // lis.splice(index,1);
        updateImageList([
            ...lis.slice(0, index),
            ...lis.slice(index + 1)
        ]);
        lis = [...lis.slice(0, index),
        ...lis.slice(index + 1)];
        console.log(imageList)
        if (lis !== undefined && lis.length > 0) {
            setCatImage(lis[0].image)
        }
        else {
            setCatImage(subcategory)
            toggleRemove();
        }
    }

    return (
        <>
            {
                isLoading ? 
                <div className='gif_loader d-flex'>
                <img src={gif} alt="Loading..." style={{ width: '50px', height: '50px' }} />
                <h1 className='pt-3'>Loading...</h1>
       
                       </div>
                :
                    <>
                        <div className="addsubcategorycontainer ms-5 me-5 mt-5">
                            <div className='subcategoryflex'>
                                {isEditing && currentIndex !== -1 && !iscropping ?
                                    <img
                                        className='editorImg'
                                        src={catImage != null ? catImage.includes("data:image") ? catImage : API_URL + catImage : subcategory}
                                        onClick={handleImageCrop}
                                        style={{ cursor: 'pointer' }}
                                        alt="img"
                                    />
                                    :
                                    isEditing && !iscropping ?

                                        <img
                                            className='editorImg'
                                            src={catImage != null ? catImage.includes("data:image") ? catImage : API_URL + catImage : subcategory}
                                            onClick={() => document.getElementById('fileInput').click()}
                                            style={{ cursor: 'pointer' }}
                                            alt="img"
                                        /> :
                                        !iscropping ?
                                            <img src={catImage != null ? catImage.includes("data:image") ? catImage : API_URL + catImage : subcategory} alt="img" />
                                            : null
                                }
                                {
                                    iscropping ?
                                        <div id="subcategory" className='imgCroper'>
                                            <Cropper
                                                src={imageToCrop}
                                                style={{ height: 300, width: '100%' }}
                                                initialAspectRatio={1}
                                                aspectRatio={1}
                                                guides={false}
                                                viewMode={1}
                                                ref={cropperRefsubCat}
                                            />
                                            <div className='btngrid'></div>
                                            <button className='cropbtn' onClick={onCrop}>Crop</button>
                                            <button className='cropbtn' onClick={cancelCrop}>Cancel</button>
                                            <button className='cropbtnbrowse' onClick={() => document.getElementById('fileInput').click()}>Browse</button>
                                        </div>
                                        : null
                                }
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={onSelectFile}
                                />
                                {/* <div className='imageSlider'>
                        {
                            imageList !== undefined && imageList.length > 0 ?
                                imageList.map((data, index) => {
                                    return (
                                        <div className='imageContain'>
                                        <img onClick={() => { setCurrentIndex(index); setCatImage(data.image) }} src={data["image"]} alt="SubCategory" />
                                        {
                                            enableRemoveImg?<FontAwesomeIcon className='removeIcon' onClick={() => removeImage(index)} icon={faPencilAlt} />:null
                                        }
                                        </div>
                                    )

                                }) : null
                        }
                        {
                            isEditing && !iscropping && !enableRemoveImg?
                                <img onClick={() => { setCurrentIndex(currentIndex + 1); document.getElementById('fileInput').click() }} className='addPng' src={addImg} alt="SubCategory" />
                                
                                : null

                        }
                        
                        {
                            imageList !== undefined && imageList.length > 0 && isEditing && !iscropping?
                                <img onClick={toggleRemove} className='addPng' src={removeImg} alt="SubCategory" /> : null
                        }
                    </div> */}
                            </div>
                            {
                                isEditing ?
                                    <div className='subcategoryflex'>

                                        <div className='contentdiv'>
                                            <div className='contentdivrow'>
                                                <label>
                                                    Prodct Name
                                                    <span className='required-asterisk'>*</span>
                                                </label>
                                                <input
                                                    placeholder={pageType === "category" ? "Enter Category Name" : "Enter SubCategory Name"}
                                                    value={name}
                                                    onChange={(e) => handleChange(e.target.value, "name")}
                                                    disabled={!isEditing}
                                                />
                                            </div>
                                            <div className='contentdivrow'>
                                                <label>
                                                    Description
                                                    <span className='required-asterisk'>*</span>
                                                </label>
                                                <textarea
                                                    placeholder={pageType === "category" ? "Enter Category Description" : "Enter SubCategory Description"}
                                                    value={description}
                                                    disabled={!isEditing}
                                                    onChange={(e) => handleChange(e.target.value, "desc")}
                                                />
                                            </div>
                                            <div className='contentdivrow'>
                                    <label>
                                        Category ID
                                        <span className='required-asterisk'>*</span>
                                    </label>
                                    {state != null?
                                    <input
                                    value={category_id}
                                        disabled={true}
                                    />
                                    :
                                    <select
                                    placeholder='select Category'
                                    value={category_id} // ...force the select's value to match the state variable...
                                    onChange={e => setCategoryID(e.target.value)} // ... and update the state variable on any change!
                                  >
                                    {
                                        catList !== undefined?
                                        catList.map((data) =>{
                                            return(
                                                <option value={data['category_id']}>{data['category_name']}</option>
                                            )
                                        })
                                        :null
                                    }
                                    
                                  </select>
}
                                </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='subcategoryflex'>
                                        {
                                            pageType === "category" ?
                                                <div className='displayContent'>
                                                    <h1>{details['category_name']}</h1>
                                                    <p>{details['category_description']}</p>
                                                </div>
                                                :
                                                <div className='displayContent'>
                                                    <h1>{details['sub_category_name']}</h1>
                                                    <p>{details['sub_category_description']}</p>
                                                </div>
                                        }
                                    </div>
                            }
                            <div className='iconContainer'>
                                <FontAwesomeIcon
                                    icon={isEditing ? faSave : faPencilAlt}
                                    className='editSaveIcon'
                                    onClick={() => {
                                        if (isEditing) {
                                            handleSave();
                                        } else {
                                            setIsEditing(true);
                                        }
                                    }}
                                />
                            </div>
                        </div>

                    </>
            }
        </>
    );
};

export default SubCategoryEdit;