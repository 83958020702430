import React, { useState, useEffect } from 'react';
import './Banners.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { API_URL } from "../../Const";
import gif from '../../Images/Intersection.gif'


const Banners = () => {
    const navigate = useNavigate();
    const [showPop, setShowPopup] = useState(false);
    const [index, setIndex] = useState(0);
    const [bannerList, setNannerList] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        getBannersData()
    }, [])

    const getBannersData = () =>{

    }

    return (
        <>
            {
                isLoading ? 
                <div className='gif_loader d-flex'>
         <img src={gif} alt="Loading..." style={{ width: '50px', height: '50px' }} />
         <h1 className='pt-3'>Loading...</h1>

                </div>

                :
                    <div className='banner'>
                        <div className='grid'>
                            {
                                bannerList.map((data, index) => {
                                    return (
                                        <div id={data['banner_id']}  > 
                                        <div className='gridContents'>
                                            {
                                                data['banner_image_url'] != null?
                                                <img src={API_URL + data['banner_image_url']} alt={data['banner_name']} />
                                            :
                                            <img src={require('../../Images/logo.png')} alt={data['banner_name']} />
                                            }
                                            <div className='let'>
                                                <h1>{data['banner_name']}</h1>
                                            </div>
                                            <div className='remove'>
                                                <FontAwesomeIcon icon="fa-solid fa-trash" />
                                                {/* <FontAwesomeIcon icon="fa-solid fa-pen" /> */}
                                            </div>
                                            <div id={data.id} >
                                                <div className='edit'>
                                                    <FontAwesomeIcon icon="fa-solid fa-eye" />
                                                    {/* <FontAwesomeIcon icon="fa-solid fa-pen" /> */}
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div  className='addButton'>
                            <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </div>
                        {
                            showPop ?
                                <div className='popup' >
                                    <div className='innerPopup'>
                                        <h1>Do you Want to Delete this?</h1>
                                        <div className='buttonDiv'>
                                            <button>Delete</button>
                                            <button>Cancel</button>
                                        </div>
                                    </div>
                                </div> : null
                        }
                    </div>
            }
        </>
    );
};

export default Banners;