import React, {useState} from 'react';
import SideDiv from '../SideDIv/SideDiv';
import RightDiv from '../RightDiv/RightDiv';
import './MainDiv.css'

const MainDiv = () => {
    // const[catSelected, setCatSelected] = useState([]);
    // const setCat = (cat) =>{
    //     setCatSelected(cat)
    // }
    return (
        <div className="maindiv">
            <SideDiv/>
            <RightDiv />
        </div>
    );
};

export default MainDiv;