//LOCAL
// export let API_URL = "http://192.168.197.119:8888/ArcheryMartAPI";
// export let PRODUCT_NAME = "ARCHERY MART";
// export let PRODUCT_LOGO = "https://karurdiscussaquarium.com/IMG_20241104_174542_198.webp";

//ArcheryMart
export let API_URL = "https://archerymart.in/ArcheryMartAPI";
export let PRODUCT_NAME = "ARCHERY MART";
export let PRODUCT_LOGO = "http://archerymart.in/logo.png";

//DISCUSS AQUARIUM
// export let API_URL = "https://karurdiscussaquarium.com/ArcheryMartAPI";
// export let PRODUCT_NAME = "DISCUSS AQUARIUM";
// export let PRODUCT_LOGO = "https://karurdiscussaquarium.com/IMG_20241104_174542_198.webp";