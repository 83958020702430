import React, { useState, useEffect } from 'react';
import './SubCategory.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import image from '../../Images/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../../Const';
import gif from '../../Images/Intersection.gif'



const SubCategory = () => {
    const navigate = useNavigate();
    const [showPop, setShowPopup] = useState(false);
    const [index, setIndex] = useState(0);

    const [subategoryList, setSubCategoryList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [currentsubCategoryID, setCurrentSubCategoryID] = useState();
    const user_id = 1;

    // const [list, setList] = useState([{ "image-url": "../../Images/logo.png", "subcategory-name": "SubCategory 1", "id": 1 },
    // { "image-url": "../../Images/logo.png", "subcategory-name": "SubCategory 2", "id": 2 },
    // { "image-url": "../../Images/logo.png", "subcategory-name": "SubCategory 3", "id": 3 },
    // { "image-url": "../../Images/logo.png", "subcategory-name": "SubCategory 4", "id": 4 },
    // { "image-url": "../../Images/logo.png", "subcategory-name": "SubCategory 5", "id": 5 },
    // { "image-url": "../../Images/logo.png", "subcategory-name": "SubCategory 6", "id": 6 }
    // ])

    useEffect(() => {
        getCategoryDetails()
        // window.scroll(0, 0)
    }, [])

    async function getCategoryDetails() {
        // alert(API_URL+"getAllActiveCategory");
        try {
            await axios.get(API_URL + "/getAllActiveSubCategory").then(function (response) {
                setSubCategoryList(response.data.result_Arr);
                console.log(response.data);
                setLoading(false);
            })

        } catch (error) {
            console.log(error);
        }
    }

    const removeCat = (index, id) => {

        setShowPopup(true);
        setIndex(index);
        setCurrentSubCategoryID(id);
    }
    const deleteCat = async () => {
        setShowPopup(false);
        const li = subategoryList;
        li.splice(index, 1);
        setSubCategoryList(li);
        setIndex(index);
        const data = {
            "category_id": currentsubCategoryID,
            "category_updated_by": user_id
        }
        await axios.post(API_URL + "/deleteCategoryById", data).then(function (response) {
            // setCategoryList(response.data.result_Arr);
            // console.log(response.data);
            // setLoading(false);
        })
    }

    const cancelDelete = () => {
        setShowPopup(false)
        setIndex(null);
    }

    const handleNavigate = (id) => {
        navigate(id, {state : {type : "subcategory"}});
    }

    const handleNavigateToEdit = (id, data) => {
        navigate(id, { state: { data: data, type: "subcategory" } });
    }

    return (
        <>
            {
                isLoading ?
                <div className='gif_loader d-flex'>
                <img src={gif} alt="Loading..." style={{ width: '50px', height: '50px' }} />
                <h1 className='pt-3'>Loading...</h1>
       
                       </div>
                        :
                    <div className='subcategoryContent'>
                        <div className='grid'>
                            {
                                subategoryList !== undefined?
                                subategoryList.map((data, index) => {
                                    return (
                                        <div id={data.id} onClick={(e) => { e.stopPropagation(); handleNavigate(`/home/subcategory/${data['sub_category_id']}`) }}> <div className='gridContents'>
                                            {
                                                data['sub_category_image_url'] != null?
                                                <img src={API_URL+data['sub_category_image_url']} alt={data['sub_category_name']} />
                                            :
                                            <img src={require('../../Images/logo.png')} alt={data['category_name']} />
                                            }

                                            
                                            <div className='let'>
                                                <h1>{data['sub_category_name']}</h1>
                                            </div>
                                            <div onClick={(e) => { e.stopPropagation(); removeCat(index,data['sub_category_id']) }} className='remove'>
                                                <FontAwesomeIcon icon="fa-solid fa-trash" />
                                                {/* <FontAwesomeIcon icon="fa-solid fa-pen" /> */}
                                            </div>
                                            <div id={data.id} onClick={(e) => { e.stopPropagation(); handleNavigateToEdit(`/home/subcategory/subcategoryedit/${data['sub_category_id']}`, data) }} >
                                                <div className='edit'>
                                                    <FontAwesomeIcon icon="fa-solid fa-eye" />
                                                    {/* <FontAwesomeIcon icon="fa-solid fa-pen" /> */}
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    )
                                }):<h1>No SubCategory Available</h1>
                            }

                        </div>
                        <div onClick={(e) => { e.stopPropagation(); handleNavigate("/home/subcategory/subcategoryedit/") }} className='addButton'>
                            <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </div>
                        {
                            showPop ?
                                <div className='popup' >
                                    <div className='innerPopup'>
                                        <h1>Do you Want to Delete this?</h1>
                                        <div className='buttonDiv'>
                                            <button onClick={deleteCat}>Delete</button>
                                            <button onClick={cancelDelete}>Cancel</button>
                                        </div>
                                    </div>
                                </div> : null
                        }
                    </div>
            }
        </>
    );
};

export default SubCategory;