import React from 'react';
import './UserList.css'
import profile from '../../Images/profile.png'

const UserList = () => {
    const rowData = [{"phoneNumber":1,"userName":"Name1", "url":"../../Images/profile.png"},
        {"phoneNumber":1,"userName":"Name1", "url":"../../Images/profile.png"},
        {"phoneNumber":1,"userName":"Name1", "url":"../../Images/profile.png"},
        {"phoneNumber":1,"userName":"Name1", "url":"../../Images/profile.png"},
        {"phoneNumber":1,"userName":"Name1", "url":"../../Images/profile.png"}
    ]
    return (
        <div className='userListContainer'>
            <div className='table'>
                <div className='tableHeader'>
                    
                    <h1>UserName</h1>
                    <h1>PhoneNumber</h1>
                    <h1>Profile</h1>
                </div>
                {
                    rowData.map(data =>{
                        return(
                            <div className='tableRow'>
                                
                                <h3>{data.userName}</h3>
                                <h3>{data.phoneNumber}</h3>
                                <div>
                                <img src={profile} alt="Profile"/>
                                </div>
                                
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default UserList;