// import React, {useState} from 'react';
// import './SideDiv.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import logo from '../../Images/logo.png'
// import {Link, useLocation} from 'react-router-dom'
// import Drawer from '../../Images/drawer.png'
// import SVGIcons from '../SvgIcons'
// import 'bootstrap/dist/css/bootstrap.min.css';

// const SideDiv = (props) => {
//     const location = useLocation();
//     const url = location.pathname;
//     return (
//         <div className='sidediv'>
//             <div className='companyDiv'>
                
            
//                 <img src={logo} alt='Logo'/>
//                 <h1>CompanyName</h1>
//             </div>
//             <div style={{display:'flex',height:'93vh',flexDirection:'column', justifyContent:"space-between"}}>
//             <ul>
//             <Link style={{textDecoration:'none'}} to={`dashboard`}><li className='side_li'> <SVGIcons.Dashboard/>  Dashboard</li></Link>
//             <Link style={{textDecoration:'none'}} to={`orders`}><li  className='side_li'> <SVGIcons.Orders/> Orders</li></Link>
//             <Link style={{textDecoration:'none'}} to={`category`}> <li  className='side_li'> <SVGIcons.Category/>  Category</li></Link>
//             <Link style={{textDecoration:'none'}} to={`subcategory`}><li className='side_li'> <SVGIcons.Sub/>  Sub Category</li></Link>
//             <Link style={{textDecoration:'none'}} to={`product`}><li className='side_li'> <SVGIcons.Product/>Products</li></Link>
//             <Link style={{textDecoration:'none'}} to={`banner`}><li className='side_li'> <SVGIcons.Images/> Banner</li></Link>
//             <Link style={{textDecoration:'none'}} to={`testimonials`}><li className='side_li'> <SVGIcons.Star/> Testimonials</li></Link>
//             <Link style={{textDecoration:'none'}} to={`userlist`}><li  className='side_li'> <SVGIcons.Userlist/>   User list</li></Link>
//             <Link style={{textDecoration:'none'}} to={`catcartegory`}><li   className='side_li'> <SVGIcons.Cart/>    Cart</li></Link>
                
//             </ul>
//            <div className="profile_section">
          
//             <div className='bottomlogout mb-3'>
//                 <SVGIcons.Logout/>
//             <h2>Logout</h2>
                
//             </div>
//             </div> 
//             </div>
//         </div>
//     );
// };

// export default SideDiv;


import React from 'react';
import './SideDiv.css';
import { Link, useLocation } from 'react-router-dom';
import SVGIcons from '../SvgIcons';
import 'bootstrap/dist/css/bootstrap.min.css';
import {PRODUCT_NAME, PRODUCT_LOGO} from '../../Const';

const SideDiv = () => {
    const location = useLocation();
    const url = location.pathname;

    return (
        <div className='sidediv'>
            <div className='companyDiv'>
                <img src={PRODUCT_LOGO} alt='Logo' />
                <h5>{PRODUCT_NAME}</h5>
            </div>
            <div style={{ display: 'flex', height: '93vh', flexDirection: 'column', justifyContent: "space-between" }}>
                <ul>
                    {/* <Link style={{ textDecoration: 'none' }} to={`dashboard`}>
                        <li className={`side_li ${url === '/home/dashboard' ? 'active' : ''}`}> 
                            <SVGIcons.Dashboard /> Dashboard
                        </li>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to={`orders`}>
                        <li className={`side_li ${url === '/home/orders' ? 'active' : ''}`}> 
                            <SVGIcons.Orders /> Orders
                        </li>
                    </Link> */}
                    <Link style={{ textDecoration: 'none' }} to="/home/dashboard">
    <li className={`side_li ${url.startsWith('/home/dashboard') ? 'active' : ''}`}>
        <SVGIcons.Dashboard /> Dashboard
    </li>
</Link>

<Link style={{ textDecoration: 'none' }} to="/home/orders">
    <li className={`side_li ${url.startsWith('/home/orders') ? 'active' : ''}`}>
        <SVGIcons.Orders /> Orders
    </li>
</Link>
                    <Link style={{ textDecoration: 'none' }} to="/home/category">
                    <li className={`side_li ${url.startsWith('/home/category') ? 'active' : ''}`}>
                        
                            <SVGIcons.Category /> Category
                        </li>
                    </Link>
                   
                    <Link style={{ textDecoration: 'none' }} to="/home/subcategory">
    <li className={`side_li ${url.startsWith('/home/subcategory') ? 'active' : ''}`}>
        <SVGIcons.Sub /> Sub Category
    </li>
</Link>


<Link style={{ textDecoration: 'none' }} to="/home/product">
    <li className={`side_li ${url.startsWith('/home/product') ? 'active' : ''}`}>
    <SVGIcons.Product /> Products
        </li>
</Link>


                    {/* <Link style={{ textDecoration: 'none' }} to={`product`}>
                        <li className={`side_li ${url === '/home/product' ? 'active' : ''}`}> 
                            <SVGIcons.Product /> Products
                        </li>
                    </Link> */}
                    <Link style={{ textDecoration: 'none' }} to={`banner`}>
                        <li className={`side_li ${url === '/home/banner' ? 'active' : ''}`}> 
                            <SVGIcons.Images /> Banner
                        </li>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to={`testimonials`}>
                        <li className={`side_li ${url === '/home/testimonials' ? 'active' : ''}`}> 
                            <SVGIcons.Star /> Testimonials
                        </li>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to={`userlist`}>
                        <li className={`side_li ${url === '/home/userlist' ? 'active' : ''}`}> 
                            <SVGIcons.Userlist /> User list
                        </li>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to={`catcartegory`}>
                        <li className={`side_li ${url === '/home/catcartegory' ? 'active' : ''}`}> 
                            <SVGIcons.Cart /> Cart
                        </li>
                    </Link>
                </ul>
                <div className="profile_section">
                    <div className='bottomlogout mb-3'>
                        <SVGIcons.Logout />
                        <h2>Logout</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideDiv;
