import logo from './logo.svg';
import './App.css';
import MainDiv from './Components/MainDiv/MainDiv';
import { faCoffee } from '@fortawesome/fontawesome-free-solid'
import 'react-image-crop/dist/ReactCrop.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import CropComp from './Components/CropComp/CropComp';
import Login from './Components/Login/Login';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      {/* <Login /> */}
      {/* <CropComp src={'./Images/logo.png'}/> */}
      <Router>
        <Routes>
          <Route path="/home/*" element={<MainDiv />} />
          <Route path="/login" element = {<h1>Login</h1>} />
            
          </Routes>
      </Router>
    </div>
  );
}

export default App;
