import React, { useState, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import defaultCatImage from '../../Images/logo.png';
import './BannerEdit.css';

const BannerEdit = () => {
  const [catImage, setCatImage] = useState(defaultCatImage);
  const [catEdit, setCatEdit] = useState(false);
  const [catDescEdit, setCatDescEdit] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const cropperRef = useRef(null);

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size > 1 * 1024 * 1024) {
        alert('File size exceeds 1MB');
        return;
      }
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!validImageTypes.includes(file.type)) {
        alert('Invalid file type. Please select an image.');
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImageToCrop(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedDataUrl = cropper.getCroppedCanvas().toDataURL();
      setCatImage(croppedDataUrl);
      setImageToCrop(null);
    }
  };

  const validateInputs = () => {
    const nameInput = document.querySelector('input[placeholder="Enter Category Name"]');
    const descriptionTextarea = document.querySelector('textarea[placeholder="Enter Category Description"]');
    let isValid = true;

    if (!nameInput.value.trim()) {
      nameInput.classList.add('invalid');
      isValid = false;
      alert('Category Name is required.');
    } else {
      nameInput.classList.remove('invalid');
    }

    if (!descriptionTextarea.value.trim()) {
      descriptionTextarea.classList.add('invalid');
      isValid = false;
      alert('Category Description is required.');
    } else {
      descriptionTextarea.classList.remove('invalid');
    }

    return isValid;
  };

  const handleSave = () => {
    if (validateInputs()) {
      const name = document.querySelector('input[placeholder="Enter Category Name"]').value;
      const description = document.querySelector('textarea[placeholder="Enter Category Description"]').value;
  
      const data = {
        name,
        description,
        image: catImage
      };
  
      console.log('JSON data:', JSON.stringify(data));
      alert('Category saved successfully!');
      setIsEditing(false);
    }
  };
  

  return (
    <div className='categoryEditContainer'>
      <div className='innderEditor'>
        <div className='imageEditor'>
          <img
            src={catImage}
            onClick={() => document.getElementById('fileInput').click()}
            style={{ cursor: 'pointer' }}
            alt="Category"
          />
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={onSelectFile}
          />
        </div>
        <div className='contentdiv'>
          <div className='contentdivrow'>
            <label>
              Category Name
              <span className='required-asterisk'>*</span>
            </label>
            {catEdit ? (
              <input placeholder='Enter Category Name' />
            ) : (
              <input
                placeholder='Enter Category Name'
                disabled={!isEditing}
              />
            )}
          </div>
          <div className='contentdivrow'>
            <label>
              Description
              <span className='required-asterisk'>*</span>
            </label>
            {catDescEdit ? (
              <textarea placeholder='Enter Category Description' />
            ) : (
              <textarea
                placeholder='Enter Category Description'
                disabled={!isEditing}
              />
            )}
          </div>
        </div>
        <div className='iconContainer'>
          <FontAwesomeIcon
            icon={isEditing ? faSave : faPencilAlt}
            className='editSaveIcon'
            onClick={() => {
              if (isEditing) {
                handleSave();
              } else {
                setIsEditing(true);
              }
            }}
          />
        </div>
      </div>
      {imageToCrop && (
        <div className='imgCroper'>
          <Cropper
            src={imageToCrop}
            style={{ height: 300, width: '100%' }}
            initialAspectRatio={1}
            aspectRatio={1}
            guides={false}
            viewMode={1}
            ref={cropperRef}
          />
          <button onClick={onCrop}>Crop</button>
        </div>
      )}
    </div>
  );
};

export default BannerEdit;
