import React, { useState, useRef, useEffect } from 'react';
import product from '../../Images/fileupload.jpg'
import './ProductEdit.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import defaultCatImage from '../../Images/logo.png';
import Cropper from 'react-cropper';
import addImg from '../../Images/add.png'
import removeImg from '../../Images/remove.png'
import { API_URL } from '../../Const'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import gif from '../../Images/Intersection.gif'

const ProductEdit = () => {

    const { state } = useLocation();

    const [productImage, setProductImage] = useState(defaultCatImage);
    const [isEditing, setIsEditing] = useState(false);
    const [iscropping, setIsCropping] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(null);
    const cropperRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [enableRemoveImg, enableRemove] = useState(false);
    const [isCheckedBox, setIsCheckedBox] = useState(false);
    const [imageList, updateImageList] = useState([]);
    const [editImage, editImageList] = useState([]);
    const [imageIdList, setImageIDList] = useState([]);
    const [details, setData] = useState();
    const [pageType, setType] = useState();
    const [catList, setCatList] = useState([]);
    const [subCatList, setSubCatList] = useState([]);

    const [productName, setProductName] = useState("");
    const [productDesc, setProductDesc] = useState("");
    const [category_id, setCategoryID] = useState("");
    const [subCategoryID, setSubCategoryID] = useState("");
    const [price, setPrice] = useState();
    const [discount, setDiscount] = useState();
    const [productCurrentPrice, setProductCurrentPrice] = useState();
    const [brandName, setBrandName] = useState("");
    const [stockQuantity, setStockQuantity] = useState();
    const user_id = 1;
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (state != null) {
            const { type } = state
            if (state.data !== undefined && state.data != null) {
                const { data } = state
                setData(data);
                console.log(state.data)
                if (type === "product") {
                    setProductImage(data['product_primary_image_url'])
                    setProductName(data['product_name'])
                    setProductDesc(data['product_description'])
                    // updateImageList()
                    const lis = [];
                    const idList = [];
                for (const i of Object.values(data['product_images'] )) {
                    lis.push({ "index": lis.length, "image": i.product_images_url })
                    idList.push({"index": lis.length, "image_id": i.product_images_id })
                    console.log("printlng the image data" + i.product_images_url)
                 }
                 
                    updateImageList(lis)
                    setImageIDList(idList)
                    editImageList(lis)
                    setCategoryID(data['product_category_id'])
                    setSubCategoryID(data['product_is_sub_category_id'])
                    setPrice(data['product_actual_price'])
                    setDiscount(data['product_offer_percentage'])
                    setProductCurrentPrice(data['product_current_price'])
                    setBrandName(data['product_brand_name'])
                    setStockQuantity(data['product_stock_quantity'])

                }

            }
            else {
                setIsEditing(true);
                setProductImage(null)
            }
            setType(type);

            
        }
        else {
            getCategoryList();
            getSubCategoryList();
            
            setIsEditing(true);
            setProductImage(null)
        }
        setLoading(false);
    }, [])


    const getCategoryList = async() => {
        await axios.get(API_URL + "/getAllActiveCategory").then(function (response) {
            setCatList(response.data.result_Arr)
        })
    }

    const getSubCategoryList = async() => {
        await axios.get(API_URL + "/getAllActiveSubCategory").then(function (response) {
            setSubCatList(response.data.result_Arr)
        })
    }

    const toggleRemove = () => {
        enableRemove(!enableRemoveImg);
    }




    const validateInputs = () => {
        // const nameInput = document.querySelector('input[placeholder="Enter Product Name"]');
        // const descriptionTextarea = document.querySelector('textarea[placeholder="Enter Product Description"]');
        // const rate = document.querySelector('textarea[placeholder="Enter Product Rate"]');
        let isValid = true;

        if (!productName.trim()) {
            // productName.classList.add('invalid');
            alert('Product Name is required.');
            return false;
            
        } 

        if (!productDesc.trim()) {
            alert('Product Description is required.');
            return false;
            
        } 

        if (!price.trim()) {
            alert('Product Rate is required.');
            return false;
            
        }
        //Need to add validation for others

        return isValid;
    };

    const handleSave = async () => {

        if (validateInputs()) {
            try {
                let arr = [];
                for (const i of Object.values(imageList )) {
                    arr.push(i.image)
                 }
                 
                // if (pageType === "product") {
                    if (details !== undefined && details !== null) {
                        const datai = {
                            product_id : details['product_id'],
                            product_name: productName,
                            product_description: productDesc,
                            product_primary_image_url: productImage,
                            product_category_id: details['product_category_id'],
                            product_is_sub_category_id: details['product_category_id'],
                            product_image_list:arr,
                            product_actual_price: parseFloat(price),
                            product_offer_percentage: parseFloat(discount),
                            product_current_price: (parseFloat(price) -((parseFloat(price)/ 100)*parseFloat(discount))),
                            product_brand_name: brandName,
                            product_stock_quantity: parseInt(stockQuantity),
                            is_featured_product: isCheckedBox?1:0,
                            product_created_by: user_id,
                            product_updated_by: user_id,
                        };
                        console.log("1")
                        await axios.post(API_URL + "/updateProductById", datai).then(function (response) {
                            console.log("2")
                            if (response.data.status === "success") {
                                alert(response.data.message);
                                setIsCropping(false);
                                setIsEditing(false);
                                navigate(-1);
                            }
                            else if (response.data.status === "failure") {
                                alert(response.data.message);
                            }
                        })
                    }
                    else {
                        const datai = {
                            product_name: productName,
                            product_description: productDesc,
                            product_primary_image_url: productImage,
                            product_category_id: category_id,
                            product_is_sub_category_id: subCategoryID,
                            product_actual_price: parseFloat(price),
                            product_image_list:arr,
                            product_offer_percentage: parseFloat(discount),
                            product_current_price: (parseFloat(price) -((parseFloat(price)/ 100)*parseFloat(discount))),
                            product_brand_name: brandName,
                            product_stock_quantity: parseInt(stockQuantity),
                            is_featured_product: isCheckedBox?1:0,
                            product_created_by: user_id,
                            product_updated_by: user_id,
                        };
                        await axios.post(API_URL + "/addNewProduct", datai).then(function (response) {
                            if (response.data.status === "success") {
                                setIsCropping(false);
                                navigate(-1);
                            }
                            else if (response.data.status === "failure") {
                                alert(response.data.message);
                            }
                        })
                    }
                // }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file.size > 1 * 1024 * 1024) {
                alert('File size exceeds 1MB');
                return;
            }
            const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (!validImageTypes.includes(file.type)) {
                alert('Invalid file type. Please select an image.');
                return;
            }
            const reader = new FileReader();
            reader.onload = () => {
                setImageToCrop(reader.result);
                setIsCropping(true);
            };
            reader.readAsDataURL(file);
        }
    };
    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            const croppedDataUrl = cropper.getCroppedCanvas().toDataURL();
            setProductImage(croppedDataUrl);
            const lis = imageList;
            alert(currentIndex);
            if (lis.length > currentIndex) {

                lis[currentIndex].image = croppedDataUrl;

            }
            else {
                lis.push({ "index": lis.length, "image": croppedDataUrl })

            }
            updateImageList(lis);
            editImageList(lis)
            setImageToCrop(null);
            setIsCropping(false);
        }
    };

    const cancelCrop = () => {
        setImageToCrop(null);
        setIsCropping(false);
    }

    const handleImageCrop = () => {

        if (imageList !== undefined && imageList.length > 0) {
            setImageToCrop(productImage); setIsCropping(true)
        }
        else {
            document.getElementById('fileInput').click()
        }
    }

    const removeImage = (index) => {
        var lis = imageList;
        if(imageIdList !== undefined && imageIdList.size > 0){
            lis[index] = imageIdList[index];
            updateImageList(lis);
        }
        else{
            updateImageList([
                ...lis.slice(0, index),
                ...lis.slice(index + 1)
            ]);
        }
        if (lis !== undefined && lis.length > 0) {
            setProductImage(lis[0].image)
        }
        else {
            setProductImage(defaultCatImage)
            toggleRemove();
        }
    }

    return (
        <>
        { isLoading ? 
 <div className='gif_loader d-flex'>
 <img src={gif} alt="Loading..." style={{ width: '50px', height: '50px' }} />
 <h1 className='pt-3'>Loading...</h1>

        </div>        :
            <div className="addproductcontainer">
                <div className='productflex left mt-5 pt-2'>
                    {/* <h1>Upload Your Files</h1> */}
                    {isEditing && currentIndex !== -1 && !iscropping ?
                        <img
                            className='editorImg'
                            src={productImage != null ? productImage.includes("data:image") ? productImage : API_URL + productImage : product}
                            onClick={handleImageCrop}
                            style={{ cursor: 'pointer' }}
                            alt="Product"
                        />
                        :
                        isEditing && !iscropping ?

                            <img
                                className='editorImg'
                                src={productImage != null ? productImage.includes("data:image") ? productImage : API_URL + productImage : product}
                                onClick={() => document.getElementById('fileInput').click()}
                                style={{ cursor: 'pointer' }}
                                alt="Product"
                            /> :
                            !iscropping ?
                            <img src={productImage != null ? productImage.includes("data:image") ? productImage : API_URL + productImage : product} alt="img" />
                                : null
                    }
                    {
                        iscropping ?
                            <div className='imgCroper'>
                                <Cropper
                                    src={imageToCrop}
                                    style={{ height: 300, width: '100%' }}
                                    initialAspectRatio={1}
                                    aspectRatio={1}
                                    guides={false}
                                    viewMode={1}
                                    ref={cropperRef}
                                />
                                <div className='btngrid'></div>
                                <button className='cropbtn' onClick={onCrop}>Crop</button>
                                <button className='cropbtn' onClick={cancelCrop}>Cancel</button>
                                <button className='cropbtnbrowse' onClick={cancelCrop}>Browse</button>
                            </div>
                            : null
                    }
                    <input
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={onSelectFile}
                    />
                    <div className='imageSlider'>
                        {
                            imageList !== undefined && imageList.length > 0 ?
                                imageList.map((data, index) => {
                                    return (
                                        <div className='imageContain'>
                                            {
                                                data.image != undefined?
                                                data.image.includes("data:image/png;base64")?
                                                <img onClick={() => { setCurrentIndex(index); setProductImage(data.image) }} src={data["image"]} alt="Product" />
                                                :<img onClick={() => { setCurrentIndex(index); setProductImage(data.image) }} src={API_URL+data["image"]} alt="Product" />:null
                                            }
                                            {
                                                enableRemoveImg ? <FontAwesomeIcon className='removeIcon' onClick={() => removeImage(index)} icon="fa-solid fa-trash" /> : null
                                            }
                                        </div>
                                    )

                                }) : null
                        }
                        {
                            isEditing && !iscropping && !enableRemoveImg ?
                                <img onClick={() => { setCurrentIndex(currentIndex + 1); document.getElementById('fileInput').click() }} className='addPng' src={addImg} alt="Product" />

                                : null

                        }

                        {
                            imageList !== undefined && imageList.length > 0 && isEditing && !iscropping ?
                                <img onClick={toggleRemove} className='addPng' src={removeImg} alt="Product" /> : null
                        }
                    </div>
                </div>
                {
                    isEditing ?
                        <div className='productflex right'>

                            <div className='contentdiv edit'>
                                <div className='contentdivrow'>
                                    <label>
                                        Product Name
                                        <span className='required-asterisk'>*</span>
                                    </label>
                                    <input
                                    value={productName}
                                    onChange={e => setProductName(e.target.value)}
                                        placeholder="Enter Product Name"
                                        disabled={!isEditing}
                                    />
                                </div>
                                <div className='contentdivrow'>
                                    <label>
                                        Category ID
                                        <span className='required-asterisk'>*</span>
                                    </label>
                                    {state != null?
                                    <input
                                    value={category_id}
                                        disabled={true}
                                    />
                                    :
                                    <select
                                    placeholder='select Category'
                                    value={category_id} // ...force the select's value to match the state variable...
                                    onChange={e => setCategoryID(e.target.value)} // ... and update the state variable on any change!
                                  >
                                    {
                                        catList.map((data) =>{
                                            return(
                                                <option value={data['category_id']}>{data['category_name']}</option>
                                            )
                                        })
                                    }
                                    
                                  </select>
}
                                </div>
                                <div className='contentdivrow'>
                                    <label>
                                        SubCategory ID
                                        <span className='required-asterisk'>*</span>
                                    </label>
                                    {state != null?
                                    <input
                                        value={subCategoryID}
                                        disabled={true}
                                    />
                                    :
                                    <select
                                    placeholder = "select Sub Category"
                                    value={subCategoryID} // ...force the select's value to match the state variable...
                                    onChange={e => setSubCategoryID(e.target.value)} // ... and update the state variable on any change!
                                  >
                                    {
                                        subCatList.map((data) =>{
                                            return(
                                                <option value={data['sub_category_id']}>{data['sub_category_name']}</option>
                                            )
                                        })
                                    }
                                  </select>
}
                                </div>
                                <div className='contentdivrow'>
                                    <label>
                                        Description
                                        <span className='required-asterisk'>*</span>
                                    </label>
                                    <textarea
                                    value={productDesc}
                                    onChange={e => setProductDesc(e.target.value)}
                                        placeholder='Enter Product Description'
                                        disabled={!isEditing}
                                    />
                                </div>
                                <div className='contentdivrow'>
                                    <label>
                                        Brand Name
                                        <span className='required-asterisk'>*</span>
                                    </label>
                                    <textarea
                                    value={brandName}
                                    onChange={e => setBrandName(e.target.value)}
                                        placeholder='Enter Brand Name'
                                        disabled={!isEditing}
                                    />
                                </div>
                                <div className='contentdivrow'>
                                    <label>
                                        Rate
                                        <span className='required-asterisk'>*</span>
                                    </label>
                                    <input
                                    type="number"
                                        value={price}
                                        onChange={e => setPrice(e.target.value)}
                                        placeholder="Enter Product Rate"
                                        disabled={!isEditing}
                                    />
                                </div>
                                <div className='contentdivrow'>
                                    <label>
                                        Discount Rate
                                    </label>
                                    <input
                                    type="number"
                                    value={discount}
                                    onChange={e => setDiscount(e.target.value)}
                                        placeholder="Enter Discount Rate"
                                        disabled={!isEditing}
                                    />
                                </div>
                                <div className='contentdivrow'>
                                    <label>
                                        Number of stocks
                                        <span className='required-asterisk'>*</span>
                                    </label>
                                    <input
                                    value={stockQuantity}
                                    
                                    onChange={e => setStockQuantity(e.target.value)}
                                        placeholder="Enter Number of stocks"
                                        disabled={!isEditing}
                                        type='number'
                                    />
                                </div>
                                {/* <div className='contentdivrow is_featured'>
                                    <label>
                                        Is Featured Product
                                    </label>
                                    <input type="checkbox" defaultChecked={false} value={isCheckedBox} onChange={() =>setIsCheckedBox(!isCheckedBox)} />
                                </div> */}
                                <div className='is_featured'>
                                <label>
                                        Is Featured Product
                                    </label>
                                    <input type="checkbox" defaultChecked={false} value={isCheckedBox} onChange={() =>setIsCheckedBox(!isCheckedBox)} />
                                </div>

                            </div>
                        </div>
                        :
                        <div className='productflex right'>
                            <div className='displayContent'>
                                <h1>{details['product_name']}</h1>
                                <p>{details['product_description']}</p>
                                <h3>Rate : {details['product_current_price']}</h3>
                                {/* <p>Available colors</p>
                                <ul style={{}}>
                                    <li style = {{listStyle: "none", height:20, width:20, backgroundColor:'red',borderRadius:"50%",display: "inline-block", marginRight:10}}></li>
                                    <li style = {{listStyle: "none", height:20, width:20, backgroundColor:'blue',borderRadius:"50%",display: "inline-block", marginRight:10}}></li>
                                    <li style = {{listStyle: "none", height:20, width:20, backgroundColor:'green',borderRadius:"50%",display: "inline-block", marginRight:10}}></li>
                                    
                                </ul> */}
                                <h3>Discount Rate : {details['product_offer_percentage']}%</h3>
                                <h3>{details['product_brand_name']}</h3>
                                <h3>Number of Stocks : {details['product_stock_quantity']}</h3>
                            </div>
                        </div>


                }
                <div className='iconContainer'>
                    <FontAwesomeIcon
                        icon={isEditing ? faSave : faPencilAlt}
                        className='editSaveIcon'
                        onClick={() => {
                            if (isEditing) {
                                handleSave();
                            } else {
                                setIsEditing(true);
                            }
                        }}
                    />
                </div>
            </div>
                        }
        </>
    );
};

export default ProductEdit;