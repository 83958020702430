import React from 'react';
import './Order.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Order = () => {
    return (
        <div className='mainContent'>
                <div className='grid'>
                    <div className='gridContents'>
                    <FontAwesomeIcon className="icon" icon="fa-solid fa-clock"/>
                    <div className='let'>
                    <h1>53</h1>
                    <h3>Pending Orders</h3>
                    </div>
                    </div>
                    <div className='gridContents'>
                    <FontAwesomeIcon className="icon" icon="fa-solid fa-clock"/>
                    <div className='let'>
                    <h1>53</h1>
                    <h3>Processing Orders</h3>
                    </div>
                    </div>
                    <div className='gridContents'>
                    <FontAwesomeIcon className="icon" icon="fa-solid fa-clock"/>
                    <div className='let'>
                    <h1>53</h1>
                    <h3>Shipped Orders</h3>
                    </div>
                    </div>
                    <div className='gridContents'>
                    <FontAwesomeIcon className="icon" icon="fa-solid fa-clock"/>
                    <div className='let'>
                    <h1>53</h1>
                    <h3>Delivered Orders</h3>
                    </div>
                    </div>
                    <div className='gridContents'>
                    <FontAwesomeIcon className="icon" icon="fa-solid fa-clock"/>
                    <div className='let'>
                    <h1>53</h1>
                    <h3>Canceled Orders</h3>
                    </div>
                    </div>
                </div>
            </div>
    );
};

export default Order;