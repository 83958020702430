import React, { useState, useEffect } from 'react';
import './Product.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import image from '../../Images/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../../Const';
import gif from '../../Images/Intersection.gif'



const Product = () => {
    const navigate = useNavigate();
    const [showPop, setShowPopup] = useState(false);
    const [index, setIndex] = useState(0);
    const [currentProductID, setDurrentProductID] = useState(0);
    const [productList, setProductList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const user_id = 1;

    useEffect(() => {
        getCategoryDetails()
    }, [])

    async function getCategoryDetails() {
        try {
            await axios.get(API_URL + "/getAllProductDetails").then(function (response) {
                setProductList(response.data.result_Arr);
                console.log(response.data);
                setLoading(false);
            })

        } catch (error) {
            console.log(error);
        }
    }


    const removeCat = (index, id) => {

        setShowPopup(true);
        setIndex(index);
        setDurrentProductID(id);
    }

    const cancelDelete = () => {
        setShowPopup(false)
        setIndex(null);
    }
    const deleteCat = async() => {

        setShowPopup(false);
        const li = productList;
        li.splice(index, 1);
        setProductList(li);
        setIndex(index);
        const data = {
            "product_id": currentProductID,
            "product_updated_by": user_id
        }
        console.log(data)
        await axios.post(API_URL + "/deleteProductById", data).then(function (response) {
        })

    }

    const handleNavigateToEdit = (id, data) => {
        navigate(id, { state: { data: data, type: "product" } });
    }
    const handleNavigate = (id) => {
        navigate(id);
    }

    return (
        <>
        {
            isLoading?
            <div className='gif_loader d-flex'>
            <img src={gif} alt="Loading..." style={{ width: '50px', height: '50px' }} />
            <h1 className='pt-3'>Loading...</h1>
   
                   </div>
            :
        
        <div className='productContent'>
            <div className='grid'>
                {
                    productList.map((data, index) => {
                        return (
                            <div id={data.product_id}  > <div className='gridContents'>
                                {
                                    data['product_primary_image_url'] != null ?
                                <img src={ API_URL+data['product_primary_image_url']} alt={data['product_name']} />
                                :<img src={require('../../Images/logo.png')} alt={data['category_name']} />
                    }
                                <div className='let'>
                                    <h1>{data['product_name']}</h1>
                                </div>
                                <div onClick={(e) => {e.stopPropagation(); removeCat(index, data.product_id)}} className='remove'>
                                    <FontAwesomeIcon icon="fa-solid fa-trash" />
                                </div>
                                {/* <div onClick={(e) => {e.stopPropagation(); handleNavigateToEdit(`/home/product/productedit/${data['product_id']}`, data)}}>
                                    <FontAwesomeIcon icon="fa-solid fa-eye" />
                                </div> */}
                                <div id={data.id} onClick={(e) => {e.stopPropagation(); handleNavigateToEdit(`/home/product/productedit/${data['product_id']}`, data)}} >
                                    <div className='edit'>
                                        <FontAwesomeIcon icon="fa-solid fa-eye" />
                                        {/* <FontAwesomeIcon icon="fa-solid fa-pen" /> */}
                                    </div>
                                </div>
                                
                            </div>
                            </div>
                        )
                    })
                }

            </div>
            <div onClick={(e) =>{e.stopPropagation(); handleNavigate("/home/product/productedit/")}} className='addButton'>
            <FontAwesomeIcon icon="fa-solid fa-plus" />
            </div>
            {
                showPop ?
                    <div className='popup' >
                        <div className='innerPopup'>
                            <h1>Do you Want to Delete this?</h1>
                            <div className='buttonDiv'>
                                <button onClick={deleteCat}>Delete</button>
                                <button onClick={cancelDelete}>Cancel</button>
                            </div>
                        </div>
                    </div> : null
            }
        </div>
        }
        </>
    );
};

export default Product;