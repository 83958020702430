import React, { useState , useEffect } from 'react';
import './RightDiv.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Order from '../Order/Order';
import { Routes, Route } from 'react-router-dom'
import Products from '../Products/Product';
import Drawer from '../../Images/drawer.png'
import {Link} from 'react-router-dom'
import Category from '../Category/Category';
import SubCategory from '../SubCategory/SubCategory';
import profile from '../../Images/profile.png'
import UserList from '../UserList/UserList';
import CategoryEdit from '../Category/CategoryEdit';
import SubCategoryEdit from '../SubCategory/SubCategoryEdit';
import ProductEdit from '../Products/ProductEdit';
import DashBoard from '../DashBoard/DashBoard';
import Banners from '../Banner/Banners';
import BannerEdit from '../Banner/BannerEdit';
import { CiSearch } from "react-icons/ci";
import SVGIcons from '../SvgIcons';

const RightDiv = () => {
    const url = "";
    const [sideDrawerHandle, setSideDrawerHandle] = useState("openDrawer");

    const chageSideDrawer = () => {
        // alert(sideDrawerHandle)
        if (sideDrawerHandle === "") {
            setSideDrawerHandle("openDrawer");

        }
        else {
            setSideDrawerHandle("");
        }
    }

    return (
        <div className='rightdiv'>
            
            <div className='nav '>
                <div onClick={chageSideDrawer} className='drawer' >
                    {/* <img src={Drawer} alt='Drawer' /> */}
                    <FontAwesomeIcon style={{fontSize:20, paddingLeft : 20, paddingRight:10}} icon="fa-solid fa-bars" />
                     <h1 style={{ display: 'inline-block', color:'blue'}}><span style={{color:'red'}}>Archery</span> Mart</h1>
                </div>

              <div className='nav_search_all'>
              <CiSearch color="black" />
              <input className='nacinput' placeholder='Search here' />
                </div>  
                <div className='welcome_text'>
                    <h1>Welcome Back, Leader! 👋🏼</h1>
                    {/* <p>Welcome to the Admin Panel of our online store!</p> */}
                </div>

               <div className='profile_icon_all pe-5 pt-2'>
               <ul>
                <li><SVGIcons.Bell/></li>
                <li><SVGIcons.Messages/></li>
                {/* <img src={profile} alt='Profile' /> */}
                <div className="dropdown-container">
          <img src={profile} alt="Profile" className="profile-image" />
          <div className="dropdown-menu">
            <ul>
              <li>View Profile</li>
              <li>Settings</li>
              <li>Logout</li>
            </ul>
          </div>
        </div>

                </ul>
                {/* <img src={profile} alt='Profile' /> */}
                </div> 
                
          
            </div>
            <Routes>
                <Route path="/" element={<DashBoard />} />
                <Route path="/dashboard" element={<DashBoard />} />
                <Route path="orders" element={<Order />} />
                <Route path="product">
                    <Route index element={<Products />} />
                    <Route path="productedit" element={<ProductEdit />} />
                    <Route path="productedit/:subID" element={<ProductEdit />} />
                </Route>
                <Route path="banner">
                    <Route index element={<Banners />} />
                    <Route path="productedit" element={<BannerEdit />} />
                    <Route path="productedit/:subID" element={<BannerEdit />} />
                </Route>
                <Route path="category">
                    <Route index element={<Category />} />
                        <Route path="categoryedit" element={<SubCategoryEdit />} />
                        <Route path="categoryedit/:catID" element={<SubCategoryEdit />} />
                    
                    <Route path=":subID" element={<SubCategory />} />
                    
                </Route>
                <Route path="subcategory">
                    <Route index element={<SubCategory />} />
                    <Route path="subcategoryedit" element={<SubCategoryEdit />} />
                    <Route path="subcategoryedit/:subID" element={<SubCategoryEdit />} />
                    <Route path=":subID" element={<Products />} />
                </Route>
                <Route path="userlist" element={<UserList />} />
            </Routes>
            <div className={`sideDrawer ${sideDrawerHandle}`}>
                <div className='top'>
                    <div onClick={chageSideDrawer} className='drawer' >
                    <FontAwesomeIcon style={{fontSize:20, paddingLeft : 20, paddingRight:10}} icon="fa-solid fa-bars" />
                        {/* <img src={Drawer} alt='Drawer' /> */}
                    </div>
                    <h1 style={{ display: 'inline-block', color:'blue'}}><span style={{color:'red'}}>Archery</span> Mart</h1>
                </div>
                <ul>
                <Link style={{textDecoration: "none"}} to={`orders`}><li onClick={chageSideDrawer}>Orders</li></Link>
                <Link style={{textDecoration: "none"}} to={`category`}><li onClick={chageSideDrawer}>Category</li></Link>
                <Link style={{textDecoration: "none"}} to={`subcategory`}><li onClick={chageSideDrawer}>Sub Category</li></Link>
                <Link style={{textDecoration: "none"}} to={`product`}><li onClick={chageSideDrawer}>Products</li></Link>
                <Link style={{textDecoration: "none"}} to={`banner`}><li onClick={chageSideDrawer}>Banner</li></Link>
                <Link style={{textDecoration: "none"}} to={`testimonials`}><li onClick={chageSideDrawer}>Testimonials</li></Link>
                <Link style={{textDecoration: "none"}} to={`userlist`}><li onClick={chageSideDrawer}>User list</li></Link>
                <Link style={{textDecoration: "none"}} to={`cart`}> <li onClick={chageSideDrawer}>Cart</li></Link>
                
            </ul>
            <div className='logout'>
            <h1>Logout</h1>
            <FontAwesomeIcon style = {{fontSize:20, padding:10, backgroundColor:'white', borderRadius:"50%", color:'black'}} icon="fa-solid fa-bell" />
                
            </div>
            </div>
            
        </div>
    );
};

export default RightDiv;