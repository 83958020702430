import React from 'react';
import './DashBoard.css'
import SVGIcons from '../SvgIcons'
import user from '../../Images/user.png'

const DashBoard = () => {
    return (
        <div class="content-wrapper">
                <h1>Dashboard</h1>
                {/* <div class="cards">
                    <div class="card">
                    <img src={require('../../Images/boy.png')} alt="pro" />

                        <p>Total User</p>
                        <h4>40,689</h4>
                        <p class="status">users joined this month</p>
                    </div>
                    <div class="card">
                        <p>Total User</p>
                        <h4>40,689</h4>
                        <p class="status">users joined this month</p>
                        <img src={require('../../Images/boy.png')} alt="pro" />
                    </div>
                    <div class="card">
                        <p>Total User</p>
                        <h4>40,689</h4>
                        <p class="status">users joined this month</p>
                        <img src={require('../../Images/boy.png')} alt="pro" />
                    </div>
                    <div class="card">
                        <p>Total User</p>
                        <h4>40,689</h4>
                        <p class="status">users joined this month</p>
                        <img src={require('../../Images/boy.png')} alt="pro" />
                    </div>
                    
                </div> */}
               <div className='cards_all mt-2'>
                <div className='row'>
                <div className='col-md-4 col-12'>
                    <div className='BG'>
                        <div className='bg_des'>
                        <h3>Total Customers</h3>
                        <p>120K</p>
                        <h6> <span>+ 30%</span> This Month</h6>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 col-12'>
                <div className='BG1'>
                <div className='bg_des'>
                        <h3>Total Orders</h3>
                        <p>120K</p>
                        <h6> <span>+ 30%</span> This Month</h6>
                        </div>
                </div>

                </div>
                <div className='col-md-4 col-12'>
                <div className='BG2'>
                <div className='bg_des'>
                        <h3>Total Revenue</h3>
                        <p>120K</p>
                        <h6> <span>+ 30%</span> This Month</h6>
                        </div>
                </div>

                </div>


                </div>
                </div> 
                <div className='row container py-2'>
                    <div className='col-md-6 col-12'>
                    <div className='recent_customer_all'>
                     <div className='recent_head'>
                        <h6>Recent Customers</h6>
                        <p>See all <SVGIcons.Seeall/></p>
                     </div>
                     <hr className='custom_hr'/>
                    <div className='recent_customer_details_all'>
                    <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                    <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        </div> 

                </div>
                    </div>
                    <div className='col-md-6 col-12'>
                    <div className='recent_customer_all'>
                     <div className='recent_head'>
                        <h6> Top Customers</h6>
                        <p>See all <SVGIcons.Seeall/></p>
                     </div>
                     <hr className='custom_hr'/>
                    <div className='recent_customer_details_all'>
                    <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                    <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        </div> 

                </div>
                    </div>

                </div>

           

                <div className='cards_all mt-2'>
                <div className='row'>
                <div className='col-md-4 col-12'>
                    <div className='BG3'>
                        <div className='bg_des'>
                        <h3>Total Processing Order</h3>
                        <p>120K</p>
                        <h6> <span>+ 30%</span> This Month</h6>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 col-12'>
                <div className='BG4'>
                <div className='bg_des'>
                        <h3>Total Shipped Order</h3>
                        <p>120K</p>
                        <h6> <span>+ 30%</span> This Month</h6>
                        </div>
                </div>

                </div>
                <div className='col-md-4 col-12'>
                <div className='BG5'>
                <div className='bg_des'>
                        <h3>Total Completed Order</h3>
                        <p>120K</p>
                        <h6> <span>+ 30%</span> This Month</h6>
                        </div>
                </div>

                </div>


                </div>
                </div> 




                <div className='row container py-2'>
                    <div className='col-md-8 col-12'>
                    <div className='recent_customer_all'>
                     <div className='recent_head'>
                        <h6>Top Selling Products</h6>
                        <p>See all <SVGIcons.Seeall/></p>
                     </div>
                     <hr className='custom_hr'/>
                    {/* <div className='recent_customer_details_all'>
                    <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                    <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        </div>  */}
                             <div className="table-container">
            <table className="product-table">
                <thead>
                    <tr>
                        <th>Images</th>
                        <th>Product Name</th>
                        <th>Category</th>
                        <th>Stock Status</th>
                        <th>Total Sales</th>
                    </tr>
                </thead>
                <tbody>
                  
                        <tr >
                            <td>
                            <img src={user} alt="User" className="user-image" />
                            </td>
                            <td>Nike Shoe N1</td>
                            <td>Sports Wear</td>
                            <td>
                            In Stock
                            </td>
                            <td>₹ 35000</td>
                        </tr>
                        <tr >
                            <td>
                            <img src={user} alt="User" className="user-image" />
                            </td>
                            <td>Nike Shoe N1</td>
                            <td>Sports Wear</td>
                            <td>
                            In Stock
                            </td>
                            <td>₹ 35000</td>
                        </tr>
                        <tr >
                            <td>
                            <img src={user} alt="User" className="user-image" />
                            </td>
                            <td>Nike Shoe N1</td>
                            <td>Sports Wear</td>
                            <td>
                            In Stock
                            </td>
                            <td>₹ 35000</td>
                        </tr>
                        <tr >
                            <td>
                            <img src={user} alt="User" className="user-image" />
                            </td>
                            <td>Nike Shoe N1</td>
                            <td>Sports Wear</td>
                            <td>
                            In Stock
                            </td>
                            <td>₹ 35000</td>
                        </tr>
                        <tr >
                            <td>
                            <img src={user} alt="User" className="user-image" />
                            </td>
                            <td>Nike Shoe N1</td>
                            <td>Sports Wear</td>
                            <td>
                            In Stock
                            </td>
                            <td>₹ 35000</td>
                        </tr>
                        <tr >
                            <td>
                            <img src={user} alt="User" className="user-image" />
                            </td>
                            <td>Nike Shoe N1</td>
                            <td>Sports Wear</td>
                            <td>
                            In Stock
                            </td>
                            <td>₹ 35000</td>
                        </tr>
                        <tr >
                            <td>
                            <img src={user} alt="User" className="user-image" />
                            </td>
                            <td>Nike Shoe N1</td>
                            <td>Sports Wear</td>
                            <td>
                            In Stock
                            </td>
                            <td>₹ 35000</td>
                        </tr>
                        <tr >
                            <td>
                            <img src={user} alt="User" className="user-image" />
                            </td>
                            <td>Nike Shoe N1</td>
                            <td>Sports Wear</td>
                            <td>
                            In Stock
                            </td>
                            <td>₹ 35000</td>
                        </tr>
                  
                </tbody>
            </table>
        </div>

                </div>
                    </div>
                    <div className='col-md-4 col-12'>
                    <div className='recent_customer_all'>
                     <div className='recent_head'>
                        <h6> Top Customers</h6>
                        <p>See all <SVGIcons.Seeall/></p>
                     </div>
                     <hr className='custom_hr'/>
                    <div className='recent_customer_details_all'>
                    <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                    <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        <div className='recent_customer_details'>
                        <div className='recent_img'>
                        <img src={user} alt="User" className="user-image" />
                        <div className='recent_detailed'>
                            <p>Jaga</p>
                            <h3>+91 9876543210</h3>
                            <h6>test@gmail.com</h6>
                        </div>
                        </div>
                       
                        <div className='recent_time'>
                            <p>25 Oct</p>
                            <h2>10.00 PM</h2>
                        </div>

                        
                        </div> 
                     <hr className='custom_hr'/>

                        </div> 

                </div>
                    </div>

                </div>

                <div class="graph">
                    <div class="title">
                        <h1>Sales Details</h1>
                        <h1>Calender</h1>
                    </div>
                    <img src={require('../../Images/graph copy.png')} alt="Graph Image" />
                </div>
        </div>
    );
};

export default DashBoard;